
:root {
  --csm-background: #F7F7F7;
  --csm-blue: #3673B2;
  --csm-red: #B93E3C;
  --csm-blue-background: #E1EAF4;
  --csm-blue-dark: #3067A0;
  --csm-text-light: #A6A6A6;
  --csm-text-medium: #807F7F;
  --csm-text-dark: black;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.csm-link {
  text-decoration: none;
}

.csm-bold {
  font-weight: 500;
}

.csm-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 175px;
}

.csm-menu {
  position: fixed;
  top: 100px;
  left: 0px;
  right: 0px;
  height: 75px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.csm-menu-title {
  padding-left: 100px;
  font-size: 22px;
  font-weight: 600;
}

.csm-menu-tab-container {
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.csm-menu-tab {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 400;
  font-size: 11pt;
  min-width: 80px;
  cursor: pointer;
}

.csm-menu-tab:hover {
  font-weight: 600;
}

.csm-form-container {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.csm-search-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
}

.csm-row-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.csm-row-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.csm-plot-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: all 0.5s;
}

.csm-plot-container:hover {
  transform: scale(1.05);
}

.csm-progress-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  opacity: 1;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.csm-progress-bar-container-hidden {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.csm-block {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 20%;
  padding-right: 20%;
}

.csm-block-shaded {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 20%;
  padding-right: 20%;
  background-color: var(--csm-background);
}

.csm-paragraph {
  font-size: 11pt;
  font-weight: 300;
  line-height: 1.7;
  padding-top: 12px;
  padding-bottom: 12px;
}

.csm-title {
  font-size: 18pt;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 18px;
}

.csm-button {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 22px;
  padding-right: 22px;
  background-color: var(--csm-blue);
  border-radius: 8px;
  color: white;
  font-size: 11pt;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.csm-button:hover {
  background-color: var(--csm-blue-dark);
}

.csm-download-button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: white;
  border-style: solid;
  border-width: 1.5px;
  border-color: var(--csm-blue);
  border-radius: 8px;
  color: var(--csm-blue);
  font-size: 11pt;
  font-weight: 400;
  cursor: pointer;
  /* letter-spacing: 1px; */
  /* text-transform: uppercase; */
  text-decoration: none;
}

.csm-download-button:hover {
  background-color: var(--csm-blue-background);
}

.csm-results-container {
  opacity: 1;
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
}

.csm-results-container-hidden {
  height: 1000px;
  opacity: 0;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.csm-results-placeholder {
  height: 100vh;
}

/* text area and select */

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--csm-text-light) !important;
}

.MuiInputBase-root.MuiFilledInput-root {
  background-color: var(--csm-background) !important;
  border-color: var(--csm-background) !important;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  color: var(--csm-blue) !important;
}

.MuiInputBase-input.MuiFilledInput-input {
  color: var(--csm-text-medium) !important;
}

.MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  border: 2px solid var(--csm-blue) !important;
}

.MuiSelect-icon {
  color: var(--csm-text-light) !important;
}

/* progress bar */

.MuiCircularProgress-svg {
  color: var(--csm-blue) !important;
}

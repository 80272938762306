
html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}

/* colors */

:root {
  --personal-primary: #5F8ECE;
  --personal-primary-hover: #517AB1;
  --personal-primary-medium: #294873;
  --personal-primary-dark: #1A2F4A;
  --personal-background: #F4F6F9;
  --personal-background-dark: #E6E8EC;
  --personal-line: #E6E8EC;
  --personal-text-title: #0D1928;
  --personal-text-light: #8A96A4;
  --personal-text-medium: #5D6977;
  --personal-text-dark: #42505E;
  --personal-text-code: #153971;
  --personal-background-code: #F4F8FE;
  --personal-shadow: 0px 0px 20px 0px rgba(63, 71, 81, 0.2);
  --personal-shadow-hover: 0px 0px 30px 0px rgba(63, 71, 81, 0.3);
}

/* general */

.personal-page-container {
  position: absolute;
  left: 60px;
  top: 0px;
  right: 0px;
}

@media only screen and (max-width: 768px) {
  div.personal-page-container {
    position: absolute;
    top: calc(80px + 60px);
    left: 0px;
    right: 0px;
  }
}

.placeholder {
  position: relative;
  height: 100vh;
  background-color: white;
  width: 100%;
}

/* text */

.personal-bold {
  font-weight: 500;
  color: var(--personal-text-dark);
}

.personal-italic {
  font-style: italic;
}

.personal-bold-italic {
  font-weight: 500;
  color: var(--personal-text-dark);
  font-style: italic;
}

.personal-code {
  background-color: var(--personal-background-dark);
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  color: var(--personal-text-code);
  font-family: "Roboto Mono";
  font-size: 10pt;
  font-weight: 400;
  opacity: 0.8;
  white-space: nowrap;
}

/* buttons */

.personal-button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.personal-chip-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 4px;
}

.personal-button-outlined {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 26px;
  padding-right: 26px;
  color: var(--personal-primary);
  border-color: var(--personal-primary);
  border-style: solid;
  border-width: 1px;
  font-weight: 400;
  font-size: 11pt;
  transition-duration: 0.3s;
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto Condensed";
  letter-spacing: 2pt;
  text-transform: uppercase;
}

.personal-button-outlined:hover {
  background-color: var(--personal-primary);
  color: white;
}

/* site banner */

.personal-site-banner {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background-color: var(--personal-primary-medium);
  z-index: 3000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 90px;
  padding-left: 90px;
  border-bottom-style: solid;
  border-color: white;
  border-width: 1px;
  overflow-x: scroll;
}

@media only screen and (max-width: 768px) {
  div.personal-site-banner {
    justify-content: center;
  }
}

.personal-site-banner-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  a.personal-site-banner-title-container {
    display: none;
  }
}

.personal-site-banner-title {
  color: white;
  min-width: 300px;
  letter-spacing: 2.5pt;
  text-transform: uppercase;
  font-size: 13pt;
  text-decoration: none;
  padding-bottom: 8px;
  font-weight: 500;
  font-family: "Roboto Condensed";
}

.personal-site-banner-subtitle {
  color: white;
  letter-spacing: 0.4pt;
  font-size: 9pt;
  text-decoration: none;
  font-weight: 300;
}

.personal-site-banner-button {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 22px;
  padding-right: 22px;
  background-color: white;
  color: black;
  letter-spacing: 2pt;
  text-transform: uppercase;
  font-size: 9pt;
  top: 55px;
  right: 60px;
  text-decoration: none;
  font-family: "Roboto Condensed";
}

.personal-site-banner-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  text-decoration: none;
}

.personal-site-banner-button:hover {
  opacity: 0.8;
}

.personal-site-banner-text-button {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 22px;
  margin-right: 22px;
  color: white;
  letter-spacing: 2pt;
  text-transform: uppercase;
  font-size: 9pt;
  top: 55px;
  right: 60px;
  text-decoration: none;
  font-family: "Roboto Condensed";
}

.personal-site-banner-text-button:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
  a.personal-site-banner-text-button {
    display: none;
  }
}

/* menu */

.personal-link-white {
  color: white;
  text-decoration: none;
}

.personal-link-white:hover {
  color: white;
  text-decoration: underline;
}

.personal-link {
  color: var(--personal-primary);
  text-decoration: none;
  font-weight: 500;
}

.personal-link:hover {
  text-decoration: underline;
}

.menu-vertical {
  position: fixed;
  top: 80px;
  bottom: 0px;
  left: 0px;
  z-index: 5000;
  height: 100vh;
  width: 60px;
  background-color: var(--personal-primary);
  border-right-style: solid;
  border-width: 1px;
  border-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2000;
}

@media only screen and (max-width: 768px) {
  div.menu-vertical {
    display: none;
  }
}

.menu-horizontal {
  position: fixed;
  top: 80px;
  left: 0px;
  right: 0px;
  height: 60px;
  background-color: var(--personal-primary);
  border-bottom-style: solid;
  border-width: 1px;
  border-color: white;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none;
  overflow-x: scroll;
}

@media only screen and (max-width: 768px) {
  div.menu-horizontal {
    display: inline;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.menu-left-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.menu-title {
  /* width: 200px; */
  /* text-align: center; */
  font-size: 16pt;
  color: white;
  transform: rotate(-90deg);
  font-family: "Roboto Condensed";
  font-weight: 600;
  /* cursor: pointer; */
  text-decoration: none;
  padding-left: 40px;
  padding-right: 60px;
}

@media only screen and (max-width: 768px) {
  div.menu-title {
    display: flex;
    transform: rotate(0deg);
    padding-left: 18px;
  }
}

.menu-button-vertical {
  position: relative;
  min-height: 120px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: white;
  cursor: pointer;
  transition-duration: 0.3s;
}

.menu-button-vertical:hover {
  background-color: var(--personal-primary-hover);
}

.menu-button-horizontal {
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right-style: solid;
  border-width: 1px;
  border-color: white;
  cursor: pointer;
  transition-duration: 0.3s;
}

.menu-button-horizontal:hover {
  background-color: var(--personal-primary-hover);
}

.menu-icon {
  color: white;
  font-size: 24;
}

.media-icon-container-vertical {
  width: 60px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
}

.media-icon-container-horizontal {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
}

.media-icon {
  color: white;
  font-size: 20pt;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.3s;
}

.media-icon:hover {
  transform: scale(1.2);
}

.menu-drawer-vertical {
  background-color: var(--personal-primary);
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  overflow-x: hidden;
  border-right-style: solid;
  border-color: white;
  border-width: 1px;
}

@media only screen and (max-width: 768px) {
  div.menu-drawer-vertical {
    display: none;
  }
}

.menu-drawer-horizontal {
  margin-top: calc(80px + 60px);
  padding-bottom: 60px;
  padding-top: 60px;
  background-color: var(--personal-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  border-bottom-style: solid;
  border-color: white;
  border-width: 1px;
}

/* @media only screen and (max-width: 768px) {
  div.menu-drawer-horizontal {
    padding-top: 120px;
    padding-bottom: 60px;
    background-color: var(--primary);
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
} */

.menu-item {
  width: 100%;
  color: white;
  font-size: 48pt;
  text-transform: uppercase;
  letter-spacing: 4pt;
  font-weight: 600;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 160px;
  cursor: pointer;
  text-decoration: none;
  transition-duration: 0.3s;
  font-family: "Roboto Condensed";
}

@media only screen and (max-width: 768px) {
  a.menu-item {
    padding-left: 32px;
    font-size: 24pt;
    letter-spacing: 2pt;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.menu-item:hover {
  background-color: var(--personal-primary-hover);
}

/* home page */

.personal-cover-image {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(95, 142, 206, 0.8) 0%, rgba(95, 142, 206, 0.4) 30%, rgba(95, 142, 206, 1) 100%);
  padding-left: 10%;
  padding-right: 10%;
}

.personal-title {
  font-size: 70pt;
  text-transform: uppercase;
  letter-spacing: 8pt;
  font-weight: 600;
  color: white;
  margin-bottom: 32px;
  font-family: "Roboto Condensed";
}

@media only screen and (max-width: 1024px) {
  div.personal-title {
    font-size: 50pt;
  }
}

@media only screen and (max-width: 768px) {
  div.personal-title {
    font-size: 24pt;
    letter-spacing: 4pt;
    margin-bottom: 12px;
  }
}

.personal-subtitle {
  font-size: 22pt;
  font-weight: 300;
  color: white;
  font-family: "Roboto Condensed";
  padding-bottom: 32px;
}

/* parallax */

.personal-parallax {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  div.personal-parallax {
    height: 300px;
  }
}

.personal-parallax-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: white;
  border-width: 1px;
  padding-bottom: 32px;
  background-image: linear-gradient(180deg, rgba(95, 142, 206, 0.5) 0%, rgba(95, 142, 206, 0.3) 50%, rgba(95, 142, 206, 1) 90%);
}

.personal-parallax-title {
  color: white;
  font-size: 50pt;
  letter-spacing: 4pt;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  div.personal-parallax-title {
    font-size: 28pt;
    letter-spacing: 2pt;
  }
}

/* footer */

.personal-footer-container {
  position: relative;
  z-index: 500;
  background-color: var(--personal-primary-medium);
  width: 100%;
  border-top-style: solid;
  border-color: white;
  border-width: 1px;
}

.personal-footer-top-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  div.personal-footer-top-content {
    justify-content: flex-start;
  }
}

.personal-footer-middle-content {
  padding-top: 50px;
  flex-direction: column;
  justify-content: center;
}

.personal-footer-bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  div.personal-footer-bottom-content {
    justify-content: flex-start;
  }
}

.personal-footer-copyright {
  color: white;
  font-size: 10pt;
  font-weight: 300;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0.5;
  font-weight: 300;
}

.personal-footer-columns {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  div.personal-footer-columns {
    flex-direction: column;
  }
}

.personal-footer-column {
  margin: 0px 50px 0px 50px;
}

@media only screen and (max-width: 768px) {
  div.personal-footer-column {
    margin: 0px 16px 0px 16px;
  }
}

.personal-footer-heading {
  min-height: 32px;
  color: white;
  font-size: 12pt;
  font-weight: 500;
  font-family: "Roboto Condensed";
  text-decoration: none;
}

.personal-footer-heading:hover {
  text-decoration: underline;
}

.personal-footer-item {
  min-height: 32px;
  color: white;
  margin: 0px;
  font-size: 10pt;
  font-weight: 300;
  text-decoration: none;
}

.personal-footer-item:hover {
  text-decoration: underline;
}

.personal-footer-break {
  height: 32px;
}

.personal-footer-title {
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-family: "Roboto Condensed";
  font-size: 24pt;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4pt;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  a.personal-footer-title {
    font-size: 15pt;
    letter-spacing: 2pt;
  }
}

/* sidebar layout */

.personal-page-content-container {
  position: absolute;
  left: 325px;
  top: 80px;
  right: 0px;
  z-index: 700;
}

@media only screen and (max-width: 1024px) {
  div.personal-page-content-container {
    left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  div.personal-page-content-container {
    margin-left: 0px;
  }
}

.personal-sidebar-container {
  width: 325px;
  background-color: var(--personal-primary);
  position: fixed;
  z-index: 800;
  left: 60px;
  top: calc(80px + 60px);
  right: 0px;
  bottom: 0px;
  overflow-y: scroll;
  padding-top: 16px;
  padding-bottom: 32px;
  border-right-style: solid;
  border-width: 1px;
  border-color: white;
}

@media only screen and (max-width: 1024px) {
  div.personal-sidebar-container {
    display: none;
  }
}

.personal-sidebar-header {
  height: 60px;
  width: calc(325px - 16px);
  background-color: var(--personal-primary-hover);
  color: white;
  border-bottom-style: solid;
  border-right-style: solid;
  border-width: 1px;
  border-color: white;
  position: fixed;
  top: 80px;
  left: 60px;
  z-index: 1000;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: "Roboto Condensed";
  font-size: 14pt;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  div.personal-page-header {
    position: absolute;
    top: 160px;
    left: 0px;
    right: 0px;
    height: 60px;
    width: unset;
    border-right-style: none;
  }
}

/* sections */

.personal-sidebar-section-text {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 32px;
  font-size: 14pt;
  font-weight: 600;
  line-height: 1.5;
  color: white;
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  letter-spacing: 2pt;
  cursor: pointer;
  transition-duration: 0.3s;
}

.personal-sidebar-section-text:hover {
  background-color: var(--personal-primary-hover);
}

.personal-sidebar-subsection-text {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  font-weight: 300;
  font-size: 11pt;
  line-height: 1.5;
  color: white;
  cursor: pointer;
  transition-duration: 0.3s;
}

.personal-sidebar-subsection-text:hover {
  background-color: var(--personal-primary-hover);
}

/* panels */

.personal-panel-grid {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  div.personal-panel-grid {
    flex-direction: column;
  }
}

.personal-panel-grid-flipped {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 768px) {
  div.personal-panel-grid-flipped {
    flex-direction: column;
  }
}

.personal-panel-column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  div.personal-panel-column {
    width: 100%;
  }
}

.personal-panel-white {
  background-color: white;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
}

@media only screen and (max-width: 768px) {
  div.personal-panel-white {
    height: unset;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.personal-panel-shaded {
  background-color: var(--personal-background);
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
}

@media only screen and (max-width: 768px) {
  div.personal-panel-shaded {
    height: unset;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.personal-panel-color {
  background-color: var(--personal-primary);
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15%;
  padding-right: 15%;
  color: white;
  font-size: 50pt;
  font-weight: 600;
  font-family: "Roboto Condensed";
}

@media only screen and (max-width: 768px) {
  div.personal-panel-color {
    height: 300px;
    font-size: 32pt;
  }
}

/* blocks */

.personal-block-color {
  width: 100%;
  height: 350px;
  background-color: var(--personal-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 60pt;
  font-weight: 800;
  font-family: "Roboto Condensed";
}

@media only screen and (max-width: 768px) {
  div.personal-block-color  {
    height: 150px;
    font-size: 36pt;
  }
}

.personal-block-white {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  div.personal-block-white {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.personal-block-gradient {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(250, 250, 250, 1));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  div.personal-block-gradient {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.personal-block-shaded {
  background-color: var(--personal-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  div.personal-block-shaded {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.personal-block-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.personal-block-content-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.personal-block-title {
  font-size: 22pt;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 8px;
  line-height: 1.5;
  font-family: "Roboto Condensed";
  color: var(--personal-primary);
}

@media only screen and (max-width: 768px) {
  div.personal-block-title {
    font-size: 18pt;
  }
}

.personal-block-subtitle {
  font-size: 13pt;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 18px;
}

.personal-block-paragraph {
  font-size: 12pt;
  font-weight: 300;
  line-height: 1.7;
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--personal-text-dark);
}

.personal-block-citation {
  font-size: 11pt;
  font-weight: 300;
  line-height: 1.7;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 16px;
  border-left-style: solid;
  border-width: 8px;
  border-color: var(--personal-background-dark);
  padding-left: 16px;
  color: var(--personal-text-light);
}

@media only screen and (max-width: 768px) {
  div.personal-block-citation {
    margin-left: 0px;
  }
}

.personal-block-abstract {
  font-weight: 500;
  font-size: 13pt;
  padding-right: 18px;
  font-family: "Roboto Condensed";
}

.personal-block-large-title {
  font-size: 50pt;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 8px;
  /* letter-spacing: 2pt; */
  /* text-transform: uppercase; */
  font-family: "Roboto Condensed";
  color: var(--personal-primary);
}

@media only screen and (max-width: 768px) {
  div.personal-block-large-title {
    font-size: 32pt;
    letter-spacing: 0pt;
    margin-bottom: 12px;
  }
}

/* modal */

.MuiModal-root.MuiDrawer-root {
  z-index: 1000;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3);
}

/* text area and select */

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 15px;
  color: var(--personal-text-medium);
  font-weight: 300;
  transform: translate(22px, 23px) scale(1);
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  color: var(--personal-primary);
  transform: translate(24px, 11px) scale(0.8);
}

.MuiInputBase-input.MuiFilledInput-input {
  color: var(--personal-text-dark);
  font-weight: 300;
  padding-top: 22px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.MuiInputBase-root.MuiFilledInput-root {
  background-color: var(--personal-background);
  border: 2px solid var(--personal-background);
  font-weight: 300;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  transition: border-color 0.3s;
}

.MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  border: 2px solid var(--personal-primary);
}

.MuiInputBase-root.MuiFilledInput-root:before {
  border-bottom: none !important;
}

.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: none !important;
}

.MuiInputBase-root.MuiFilledInput-root:before:hover {
  border-bottom: none;
}

.MuiFormHelperText-root {
  font-weight: 300;
  font-size: 13px;
  color: var(--personal-text-light);
  margin-top: 6px;
  margin-left: 22px;
  margin-right: 22px;
}

.MuiInputAdornment-root {
  padding-right: 8px;
}

.MuiButtonBase-root.MuiIconButton-root {
  color: var(--personal-primary);
}

.MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: var(--personal-background-hover);
}


.MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
  background-color: var(--personal-background);
}

.MuiSelect-icon {
  color: var(--personal-text-light);
  padding-right: 8px;
  padding-left: 8px;
  transition: transform 0.3s;
}

/* popup menu */

.MuiPopover-paper {
  box-shadow: var(--personal-shadow);
  padding-top: 12px;
  padding-bottom: 12px;
}

.MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiMenuItem-root {
  background-color: white  !important;
  font-size: 15px;
  color: var(--personal-text-dark);
  font-weight: 300;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
}

.MuiMenuItem-root:hover {
  background-color: var(--personal-background) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--personal-primary) !important;
  color: white;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--personal-primary-hover) !important;
  color: white;
}

.MuiPopover-root {
  z-index: 6100;
}

.MuiPopover-paper {
  margin-top: 16px;
  margin-left: 16px;
  border-radius: 8px;
}

/* chips */

.MuiChip-root {
  font-size: 10pt;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.personal-chip.MuiChip-root {
  margin-right: 12px;
  margin-bottom: 12px;
  color: var(--personal-text-dark);
  background-color: var(--personal-background-dark);
}

.personal-chip-color.MuiChip-root {
  margin-right: 12px;
  margin-bottom: 12px;
  color: white;
  background-color: var(--personal-primary);
}

.MuiButtonBase-root.MuiChip-root {
  background-color: var(--personal-primary);
  font-size: 13px;
  color: white;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.MuiButtonBase-root.MuiChip-root:hover {
  background-color: var(--personal-primary-hover);
}

.MuiTouchRipple-root {
  display: none !important;
  opacity: 0 !important;
}

/* progress bar */

.MuiCircularProgress-root {
  animation-duration: 800ms;
}

.MuiCircularProgress-svg {
  color: var(--personal-primary);
  stroke-linecap: round;
}
